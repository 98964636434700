<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error
      </h4>
      <div class="alert-body">
       Geen contact gevonden met dit id, ga terug naar het
        <b-link
          class="alert-link"
          :to="{ name: 'contact-list'}"
        >
          overzicht
        </b-link>
       voor andere contacten.
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Persoonlijke gegevens</span>
        </template>
        <contact-edit-tab-account
        @resetData="resetData"
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import ContactEditTabAccount from './ContactEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ContactEditTabAccount,
  },

  data(){
    return {
      userData: null,
    }
  },

  created()
  {
    if (this.$store.state.organisation) {
      this.loadUser();
    }

    this.unwatch = this.$store.watch(
      (state) => state.organisation,
      (oldValue, newValue) => {
        if (this.$store.state.organisation) {
          this.loadUser();
        }
      }
    );

  },

  methods: {
    resetData(){
      this.loadUser();
    },
    loadUser() {
      const id = this.$route.params.id
    this.$store.dispatch('userManagement/fetchContact', {id, orgId: this.$store.state.organisation.id})
      .then(res => { 
        this.userData = res; 
        this.$store.commit('SET_PAGE_TITLE', this.userData.displayName);
        })
      .catch(err => {
        this.$router.push({name: 'users-list'});
        
        console.error(err) 
        return
      })
    
    }
  }
  
}
</script>

<style>

</style>
